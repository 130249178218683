<template>
  <topic-comment-card
    :comment.sync="document.text"
    :commentColor="commentColor"
    :createAt="document.createAt"
    :deleteComment="deleteCommentTopic"
    :dense="dense"
    :filled="filled"
    :index="index"
    :outlined="outlined"
    :placeholder="placeholder"
    :rows="rows"
    :saveComment="sendInternaly"
    :showActionButton="!document.id"
    :showEditButton="canUpdate && !!document.id"
    :showUserInformation="!!document.id"
    :update-at="document.updateAt"
    :userId="createBy"
    ref="topic-comment"
  />
</template>

<script>

import DocumentEditor from '@/mixins/document-editor'

export default {
  mixins: [DocumentEditor],
  components: {
    TopicCommentCard: () => import('./topic-comment-card')
  },
  data () {
    return {
      isConfidential: true,
      edit: false,
      oldText: null
    }
  },
  methods: {
    async sendInternaly () {
      this.isConfidential = true
      const promise = await this.saveDocument()
      return promise
    },
    async sendExternaly () {
      this.isConfidential = false
      const promise = await this.saveDocument()
      return promise
    },
    async save () {
      if (this.id) {
        await this.$http().post(`/core/v6/comments/${this.id}`, this.commentToJSON())
      } else {
        await this.$http().post(`/core/v6/topics/${this.topicId}/comments`, this.commentToJSON())
      }
    },
    commentToJSON () {
      return {
        emoji: '',
        text: this.document.text,
        confidential: this.isConfidential
      }
    },
    editComment () {
      this.edit = true
      this.oldText = this.document.text
    },
    cancelComment () {
      this.edit = false
      this.document.text = this.oldText
    },
    async deleteCommentTopic () {
      await this.$http().delete(`/core/v6/comments/${this.id}`)
      this.refreshCache()
    }
  },
  computed: {
    cacheType () {
      return DocumentEditor.CacheType.CommentDetail
    },
    id () {
      return this.commentId
    },
    createBy () {
      return this.document.createBy ?? this.$store.getters.currentUser.id
    },
    canUpdate () {
      return this.createBy === this.$store.getters.currentUser.id
    },
    commentColor () {
      return !this.document.confidential && !!this.document.id ? 'secondary' : undefined
    }
  },
  props: {
    commentId: Number,
    dense: Boolean,
    filled: Boolean,
    index: Number,
    outlined: Boolean,
    placeholder: String,
    rows: {
      type: Number,
      default: 1
    },
    topicId: Number
  }
}
</script>

<style lang="stylus" scoped>
>>> .v-input.v-text-field.v-input--is-readonly .v-input__slot::before
  border none
</style>
