import ClientCache from './client-cache'

export default {
  CacheType: ClientCache.CacheType,
  mixins: [ClientCache],
  data () {
    return {
      document: {},
      isChangedOutside: false,
      isDocumentLoadedFromCache: false,
      isPristine: true,
      isReady: false,
      refreshDocumentCallbackTimeoutId: undefined,
      saveInProgress: false,
      unwatchHandler: undefined
    }
  },
  computed: {
    documentIsNew () {
      return !this.cache?.doc?.id
    }
  },
  activated () {
    this.watchDoc()
  },
  mounted () {
    this.watchDoc()
  },
  beforeDestroy () {
    this.unwatchDoc()
  },
  deactivated () {
    this.unwatchDoc()
  },
  methods: {
    cacheWatcherHandler (v) {
      if (typeof v !== 'undefined') {
        if (this.isPristine) {
          this.refreshDocument()
        } else {
          this.isChangedOutside = true
        }
      }
    },
    async cloneDocument () {
      await this.$waitFor(() => this.isDocumentLoadedFromCache)
      await this.resetDocumentId()
    },
    async resetDocumentId () {
      this.cache.doc.id = undefined
      this.id = undefined
      this.document.id = undefined
    },
    async refreshDocument () {
      clearTimeout(this.refreshDocumentCallbackTimeoutId)
      this.refreshDocumentCallbackTimeoutId = setTimeout(() => {
        this.innerRefreshDocument()
      }, 100)
    },
    async innerRefreshDocument () {
      this.startReloadingDocument()

      // Await the cache finished loading (if there is no cache nor status, it's OK, it's a new document)
      await this.$waitFor(() => this.cache?.status !== 1)

      // We must deep clone the cached doc to prevent subobject or array entries to be altered
      const d = this.cache?.doc

      this.document = Object.assign(
        {},
        this.document,
        this.lodash.cloneDeep(d ?? {})
      )
      this.isDocumentLoadedFromCache = !!this.cache?.doc

      if (typeof this.refreshDocumentHook === 'function') {
        await this.refreshDocumentHook()
          .catch(e => console.error(`An error occured when running refreshDocumentHook(). cacheType: ${this.cacheType} id: ${this.id}`, e))
      }

      await this.$nextTick()

      this.isPristine = true
      this.isChangedOutside = false
      this.stopReloadingDocument()
    },
    async saveDocument () {
      if (typeof this.save === 'function' && !this.saveInProgress) {
        this.saveInProgress = true
        try {
          await this.save?.()
          this.$emit('document-saved', this.tab)
          this.refreshCache()
          await this.$nextTick()
          this.isPristine = true
        } finally {
          this.saveInProgress = false
        }
      }
    },
    startReloadingDocument () {
      this.isReady = false
    },
    stopReloadingDocument () {
      this.isReady = true
      this.$emit('document-reset', this.tab)
    },
    unwatchDoc () {
      this.watchCacheWith()
    },
    watchCacheWith (watchFn) {
      if (this.unwatchHandler && !watchFn) {
        this.unwatchHandler()
        this.unwatchHandler = undefined
      }
      if (!this.unwatchHandler && watchFn) {
        this.unwatchHandler = watchFn()
      }
    },
    watchDoc (immediate = true) {
      this.watchCacheWith(() => this.$watch('cache.doc', this.cacheWatcherHandler, { deep: true, immediate }))
    }
  },
  watch: {
    id (id) {
      this.isDocumentLoadedFromCache = false
      // this.isDocumentLoadedFromCache = false
      if (id) {
        this.refreshDocument()
      }
    },
    document: {
      deep: true,
      immediate: false,
      handler () {
        if (this.isPristine && this.isReady) {
          this.isPristine = false
          this.$emit('document-changed', this.tab)
        }
      }
    }
  }
}
