import { DocSync, CacheType, refreshCache } from '@/wasm/pkg'

const mixin = {
  CacheType,
  activated () {
    this.cacheMixinDocSync.active = true
  },
  data: function () {
    const cacheMixinDocSync = new DocSync()
    return {
      cache: cacheMixinDocSync.obs,
      cacheMixinDocSync
    }
  },
  deactivated () {
    this.cacheMixinDocSync.active = false
  },
  destroyed () {
    this.cacheMixinDocSync.free()
  },
  methods: {
    refreshCache () {
      refreshCache()
    }
  },
  mounted () {
    this.cacheMixinDocSync.active = true
  },
  watch: {
    cacheType: {
      immediate: true,
      handler (v) {
        this.cacheMixinDocSync.cacheType = v
      }
    },
    id: {
      immediate: true,
      handler (v) {
        this.cacheMixinDocSync.id = v
      }
    }
  }
}

export default mixin
